import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme } from '@mui/material'

/**
 * Utils
 */
import { FunnelReportItem } from '../FunnelReport'
import { format } from 'date-fns'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'

/**
 * Icons
 */
import { ChevronLeft, Person, Place } from '@mui/icons-material'
import { generateStyle } from '../../../_utils/DefaultStyles'

interface FunnelReportClosingListProps {
    item: null | FunnelReportItem
    onClose: () => void
    isMobile: boolean
    date: null | string
}

type Item = {
    utj_date: string
    client_name: string
    cluster_name: string
    status_label: string
}

const FunnelReportClosingList = (props: FunnelReportClosingListProps) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [items, setItems] = useState<Item[]>([])

    useEffect(() => {
        if (props.item?.id) fetchData()

        return () => {
            setItems([])
        }
        // eslint-disable-next-line
    }, [props.item?.id])

    const fetchData = () => {
        if (!props.item?.id) return
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/funnel-report/agent-closing`, {
            params: {
                agent_id: props.item.id,
                date: props.date,
            }
        })
            .then(res => res.data)
            .then(data => {
                setItems(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Dialog
            open={!!props.item}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
            fullScreen={props.isMobile}
        >
            <Root>
                <DialogTitle className={classes.appBar}>
                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid>
                            <IconButton aria-label="back" onClick={() => props.onClose()} style={{ marginLeft: -8, marginRight: 2, color: '#fff' }}>
                                <ChevronLeft />
                            </IconButton>
                            <span>Closing List - {props.item?.name || ''}</span>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {
                        isLoading ?
                            <div className={classes.card} style={{ textAlign: 'center' }}>
                                <CircularProgress size={32} />
                            </div>
                            :
                            items.length ?
                                items.map((item, index) => (
                                    <div key={index} className={classes.card}>
                                        <div className={classes.date}>
                                            {item.utj_date ? format(new Date(item.utj_date), 'EEEE, dd MMM yyyy') : '-'}
                                        </div>
                                        <div className={classes.bottomContainer}>
                                            <div className={classes.status}>
                                                {item.status_label}
                                            </div>
                                            <div>
                                                <div className={classes.dataContainer}>
                                                    <Person fontSize='small' className={classes.icon} />
                                                    <div className={classes.data}>{item.client_name || '-'}</div>
                                                </div>
                                                <div className={classes.dataContainer}>
                                                    <Place fontSize='small' className={classes.icon} />
                                                    <div className={classes.data}>{item.cluster_name || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className={classes.card}>
                                    Maaf, list closing tidak ditemukan
                                </div>
                    }
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 0,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    card: {
        padding: 16,
        paddingTop: 8,
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
    },
    date: {
        fontSize: 12,
        marginBottom: 8,
    },
    bottomContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    status: {
        padding: 4,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
        marginRight: 16,
        width: 90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 14,
    },
    dataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
    },
    icon: {
        marginRight: 8,
    },
    data: {
        fontSize: 14,
    },
}), "FunnelReportClosing_List"
);

export default FunnelReportClosingList
