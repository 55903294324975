import React, { useState, useEffect } from 'react';
import {
    Dialog, Grid, Paper, Tabs, Tab, Theme, Button, Chip, IconButton, TextField,
    Typography
} from '@mui/material';
import Swal from 'sweetalert2';
import format from 'date-fns/format'

/**
 * Components
 */
import ModalDetail from '../../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../../_components/_modalDetail/ModalSection';
import FileList from './FileList';
import CancelClosingModal from './CancelClosingModal';
import LoadingScreen from '../../../_components/LoadingScreen';
import TransactionList from './TransactionList';
import LogList from './LogList';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

/**
 * Utils
 */
import { convertNumber, numberToCurrency } from '../../../_utils/Helpers';
import { dateFormat, generalErrorHandler, inputNumber, renderWarningButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import ListPinjamanModal from './ListPinjamanModal';
import PinjamanList from './PinjamanList';
import PembayaranList from './PembayaranList';
import { generateStyle } from '../../../_utils/DefaultStyles';
import ChangeAdvisorModal from './ChangeAdvisorModal';

const useStyles = generateStyle((theme: Theme) => ({
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
}), "ClosingModalDetail"
)

interface IProps {
    open: boolean
    closing_id: string
    onClose: Function
    permissions: any
}

interface IState {
    id: string
    code: string
    status_label: string
    lead_label: string
    created_at: string
    client_name: string
    client_phone: string
    client_email: string
    cluster_name_label: string
    unit_information: string
    transaction_amount: number
    estimated_transaction_amount: number
    agent_name: string
    commission_percentage: string
    agent_full_name: string
    notes: string
    booking_file: string
    is_cobroke: number
    is_lead_personal: number
    is_telesales: number
    // borrowed_commission_amount: number
    // borrowed_commission_transfer_date: string
    // cobroke_borrowed_commission_amount: number
    // cobroke_borrowed_commission_transfer_date: string
    cobroke_agent_name: string
    cobroke_percentage: string
    status: string
    payment_type: string
    payment_bank_name: string
    payment_installments: string
    down_payment_nominal: number
    down_payment_installments: string
    utj_date: string
    sales_order_date: string
    contract_agreement_date: string
    transactions: {
        [key: string]: ITransaction
    }
    loan_paid_closing_code: string
    loan_paid_cobroke_closing_code: string
    agent_group_name: string | null
    cobroke_agent_group_name: string | null
}

export interface ITransaction {
    agent_commission_amount: number,
    agent_nett_amount: number
    agent_pph21_amount: number
    approved_at: string
    approved_by: string
    billed_nominal: number
    closing_id: number
    cobroke_commission_amount: number
    cobroke_nett_amount: number
    cobroke_pph21_amount: number
    code: string
    created_at: string
    created_by: string
    date: string
    dpp_amount: number
    escrow_status: string
    estimated_date: string
    estimated_nominal: number
    id: number
    is_pph23: number
    is_ppn: number
    name: string
    nominal: number
    notes: string
    office_commission_amount: number
    pph23_amount: number
    ppn_amount: number
    processed_at: string
    processed_by: string
    proposed_at: string
    proposed_by: string
    released_at: string
    released_by: string
    status: string
    tax_invoice_date: string
    type: string
    updated_at: string
}

const initialState = {
    id: "",
    code: "",
    lead_label: "",
    status_label: "",
    created_at: "",
    client_name: '',
    client_phone: "",
    client_email: "",
    cluster_name_label: "",
    unit_information: "",
    transaction_amount: 0,
    estimated_transaction_amount: 0,
    agent_name: "",
    commission_percentage: "",
    agent_full_name: "",
    notes: "",
    booking_file: "",
    is_cobroke: 0,
    is_lead_personal: 0,
    is_telesales: 0,
    // borrowed_commission_amount: 0,
    // borrowed_commission_transfer_date: '',
    // cobroke_borrowed_commission_amount: 0,
    // cobroke_borrowed_commission_transfer_date: '',
    cobroke_agent_name: '',
    cobroke_percentage: '',
    status: '',
    payment_type: '',
    payment_bank_name: '',
    payment_installments: '',
    down_payment_nominal: 0,
    down_payment_installments: '',
    utj_date: '',
    sales_order_date: '',
    contract_agreement_date: '',
    transactions: {},
    loan_paid_closing_code: "",
    loan_paid_cobroke_closing_code: "",
    agent_group_name: null,
    cobroke_agent_group_name: null,
}

const ClosingModalDetail = ({ open, closing_id, onClose, permissions }: IProps) => {
    const { Root, classes } = useStyles();
    const [data, setData] = useState<IState>(initialState)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0);
    const [activePinjamanTab, setActivePinjamanTab] = useState(0);
    const [footer, setFooter] = useState<any>(null);
    const [footerPinjaman, setFooterPinjaman] = useState<any>(null);
    const [isFooterLoading, setIsFooterLoading] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [listPinjamanModalOpen, setListPinjamanModalOpen] = useState(false)
    const [estimatedPriceForm, setEstimatedPriceForm] = useState({
        open: false,
        estimated_transaction_amount: 0
    })
    const [changeAdvisorModal, setChangeAdvisorModal] = useState({
        open: false,
        type: 0
    })

    const handleCancelModalOpen = () => setCancelModalOpen(true);
    const handleCancelModalClose = () => setCancelModalOpen(false);
    // const handleListPinjamanModalOpen = () => setListPinjamanModalOpen(true)
    const handleListPinjamanModalClose = () => setListPinjamanModalOpen(false)

    const handleUploadFile = (fd: FormData) => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/closing/${closing_id}/file`, fd)
            .then(res => {
                Swal.fire({
                    title: "Upload berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadFooter();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleSubmitPinjaman = (state: any) => {
        setIsFooterLoading(true);
        const data = {
            ...state,
            date: state.dates ? dateFormat(state.dates, 'YYYY-MM-DD') : null,
            nominal: inputNumber(state.nominal),
            nominal_cobroke: inputNumber(state.nominal_cobroke),
            closing_id: closing_id,
        }
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/closing-loan`, data)
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadFooterPinjaman();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    // const handleSubmitTransaction = (state: any, id: string) => {
    //     setIsFooterLoading(true);
    //     const data = {
    //         type: state.type,
    //         name: state.name,
    //         estimated_date: dateFormat(state.estimated_date, 'YYYY-MM-DD'),
    //         estimated_nominal: state.estimated_nominal,
    //         date: state.date ? dateFormat(state.date, 'YYYY-MM-DD') : null,
    //         nominal: state.nominal
    //     }
    //     const API_URL = `${process.env.REACT_APP_API_URL}/closing/${closing_id}/transaction`
    //     const API_URL_UPDATE = `${process.env.REACT_APP_API_URL}/closing/${closing_id}/transaction/${id}`

    //     DefaultAxios.request({
    //         url: id ? API_URL_UPDATE : API_URL,
    //         method: id ? 'PATCH' : 'POST',
    //         data: data,
    //     })
    //         // DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing/${closing_id}/transaction`, data)
    //         .then(res => {
    //             Swal.fire({
    //                 title: "Submit berhasil",
    //                 icon: 'success',
    //                 timer: 1000
    //             })
    //                 .then(res => {
    //                     loadTransaction();
    //                 })
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsFooterLoading(false);
    //         });
    // }

    // const loadTransaction = () => {
    //     setTransaction(
    //         <TransactionList
    //             closingId={closing_id}
    //             data={data.transactions}
    //             onSubmitData={handleSubmitTransaction}
    //             onDeleteData={() => loadTransaction()}
    //         />
    //     )
    // }

    const handleSubmitEstimatedPrice = () => {
        renderWarningButton('Apakah anda yakin ingin mengganti Estimasi nilai transaksi?')
            .then((result) => {
                setIsLoading(true);
                const payload = {
                    id: data.id,
                    estimated_transaction_amount: estimatedPriceForm.estimated_transaction_amount
                }
                DefaultAxios
                    .post(`${process.env.REACT_APP_API_URL}/closing-admin/update-estimated-transaction-amount`, payload)
                    .then(res => {
                        Swal.fire({
                            title: "Update berhasil",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(res => {
                                fetchData()
                            })
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setEstimatedPriceForm(prev => ({
                            ...prev,
                            open: false
                        }))
                        setIsLoading(false);
                        setIsFooterLoading(false);
                    })
            })
    }

    const loadFooter = () => {
        let url: string = '';

        setIsFooterLoading(true);

        switch (activeTab) {
            case 0:
                url = `${process.env.REACT_APP_API_URL}/closing/${closing_id}/file`;
                break;
            case 1:
                url = `${process.env.REACT_APP_API_URL}/closing/${closing_id}/log`;
                break;

            default:
                break;
        }

        DefaultAxios
            .get(url, {
                params: {
                    closing_id: closing_id
                }
            })
            .then(res => {
                switch (activeTab) {
                    case 0:
                        setFooter(
                            <FileList
                                closing_id={closing_id}
                                permissions={permissions}
                                files={res.data}
                                onUploadFile={handleUploadFile}
                                onDeleteFile={() => loadFooter()}
                            />
                        )
                        break;
                    case 1:
                        setFooter(
                            <LogList
                                closingId={closing_id}
                                logs={res.data}
                            />
                        )
                        break;
                    default:
                        break;
                }

            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const loadFooterPinjaman = () => {
        let url: string = '';

        switch (activePinjamanTab) {
            case 0:
                url = `${process.env.REACT_APP_API_URL}/closing-loan`;
                break;
            case 1:
                url = `${process.env.REACT_APP_API_URL}/closing-loan/paid-loan`;
                break;
            default:
                break;
        }

        if (!url || !closing_id) {
            return;
        }

        setIsFooterLoading(true);
        DefaultAxios
            .get(url, {
                params: {
                    id: closing_id
                }
            })
            .then(res => {
                switch (activePinjamanTab) {
                    case 0:
                        setFooterPinjaman(
                            <PinjamanList
                                permissions={permissions}
                                closingId={closing_id}
                                data={res.data}
                                onSubmit={handleSubmitPinjaman}
                                onDelete={() => loadFooterPinjaman()}
                                agent_name={data.agent_name}
                                cobroke_name={data.cobroke_agent_name}
                                is_cobroke={data.is_cobroke}
                            />
                        )
                        break;
                    case 1:
                        setFooterPinjaman(
                            <PembayaranList
                                permissions={permissions}
                                closingId={closing_id}
                                data={res.data}
                                onDelete={() => loadFooterPinjaman()}
                                agent_name={data.agent_name}
                                cobroke_name={data.cobroke_agent_name}
                                is_cobroke={data.is_cobroke}
                            />
                        )
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    const handlePinjamanTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActivePinjamanTab(newValue);
    }

    const handleEstimatedPrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;
        const isNumber = ['estimated_transaction_amount'].includes(name)

        setEstimatedPriceForm(prev => ({
            ...prev,
            [name]: isNumber ? inputNumber(value) : value
        }))
    }

    const changeAdvisor = (type: number) => {
        setChangeAdvisorModal({
            open: true,
            type
        })
    }

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-admin/${closing_id}`)
            .then(res => {
                const data = res.data;
                setData({
                    id: data.id,
                    code: data.code,
                    lead_label: data.lead_label,
                    status_label: data.status_label,
                    created_at: data.created_at,
                    client_phone: data.client_phone,
                    client_email: data.client_email,
                    cluster_name_label: data.cluster_name_label,
                    unit_information: data.unit_information,
                    estimated_transaction_amount: data.estimated_transaction_amount,
                    transaction_amount: data.transaction_amount,
                    agent_name: data.agent_name,
                    commission_percentage: data.commission_percentage,
                    agent_full_name: data.agent_full_name,
                    notes: data.notes,
                    booking_file: data.booking_file,
                    is_cobroke: data.is_cobroke,
                    is_lead_personal: data.is_lead_personal,
                    is_telesales: data.is_telesales,
                    // borrowed_commission_amount: data.borrowed_commission_amount,
                    // borrowed_commission_transfer_date: data.borrowed_commission_transfer_date,
                    // cobroke_borrowed_commission_amount: data.cobroke_borrowed_commission_amount,
                    // cobroke_borrowed_commission_transfer_date: data.cobroke_borrowed_commission_transfer_date,
                    cobroke_agent_name: data.cobroke_agent_name,
                    cobroke_percentage: data.cobroke_percentage,
                    status: data.status,
                    payment_type: data.payment_type,
                    payment_bank_name: data.payment_bank_name,
                    payment_installments: data.payment_installments,
                    down_payment_nominal: data.down_payment_nominal,
                    down_payment_installments: data.down_payment_installments,
                    utj_date: data.utj_date,
                    sales_order_date: data.sales_order_date,
                    contract_agreement_date: data.contract_agreement_date,
                    client_name: data.client_name,
                    transactions: data.transactions,
                    loan_paid_closing_code: data.loan_paid_closing_code,
                    loan_paid_cobroke_closing_code: data.loan_paid_cobroke_closing_code,
                    agent_group_name: data.agent_group_name,
                    cobroke_agent_group_name: data.cobroke_agent_group_name,
                })
                setEstimatedPriceForm(prev => ({
                    ...prev,
                    estimated_transaction_amount: data.estimated_transaction_amount
                }))
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [open, closing_id])

    // const openModalConfirmation = (type: string) => {
    //     renderWarningButton(`Apakah anda yakin ingin mengubah status menjadi ${type === 'complete' ? 'Complete' : 'Cancel'}?`)
    //         .then((result) => {
    //             if (result.value) {
    //                 setIsLoading(true)
    //                 DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing/${closing_id}/${type}`)
    //                     .then(res => {
    //                         Swal.fire({
    //                             title: "Berhasil mengganti status",
    //                             icon: 'success',
    //                             timer: 1000
    //                         })
    //                             .then(res => {
    //                                 fetchData()
    //                             })
    //                     })
    //                     .catch(err => {
    //                         generalErrorHandler(err);
    //                     })
    //                     .finally(() => {
    //                         setIsLoading(false)
    //                     });
    //             }
    //         })
    // }

    useEffect(() => {
        loadFooter();
        // eslint-disable-next-line
    }, [activeTab, closing_id]);

    useEffect(() => {
        loadFooterPinjaman()
        // eslint-disable-next-line
    }, [activePinjamanTab, data.id]);

    return (
        <Root>
            <CancelClosingModal
                open={cancelModalOpen}
                onClose={handleCancelModalClose}
                closingId={data.id}
                status={data.status}
                setIsLoading={setIsLoading}
                cancelCallback={() => {
                    handleCancelModalClose();
                    fetchData();
                }}
            />
            {listPinjamanModalOpen &&
                <ListPinjamanModal
                    closing_id={closing_id}
                    open={listPinjamanModalOpen}
                    onClose={handleListPinjamanModalClose}
                    is_cobroke={data.is_cobroke}
                />

            }

            <ChangeAdvisorModal
                open={changeAdvisorModal.open}
                id={data.id}
                type={changeAdvisorModal.type}
                onClose={(refresh) => {
                    setChangeAdvisorModal({
                        open: false,
                        type: 0
                    })

                    if (refresh) fetchData()
                }}
            />

            <ModalDetail
                title={`Detail Closing - ${data.code}`}
                open={open}
                onCloseModal={() => onClose()}
                isLoading={isLoading}
                showEditButton={permissions['closing.edit'] && data.status !== 'cancel'}
                editButtonLink={`/closing/edit/${closing_id}`}
            >
                <>
                    <ModalSection
                        md={6}
                        xs={12}
                        title={"Detail"}
                        content={[
                            ['Status', data.status_label],
                            ['Cobroke', data.is_cobroke ? 'Yes' : 'No'],
                            data.is_lead_personal === 1 ?
                                ['Lead Label',
                                    <>
                                        <span>{data.lead_label}</span> <Chip style={{ marginRight: 12 }} color={"primary"} size='small' label='Leads Pribadi' />
                                    </>
                                ] : ['Lead Label', data.lead_label],
                            ['Tanggal Input', data.created_at ? format(new Date(data.created_at), 'dd MMMM yyyy') : ''],
                            ['Tanggal UTJ', data.utj_date ? format(new Date(data.utj_date), 'dd MMMM yyyy') : ''],
                            ['Tanggal Surat Pemesanan', data.sales_order_date ? format(new Date(data.sales_order_date), 'dd MMMM yyyy') : ''],
                            ['Tanggal Akad', data.contract_agreement_date ? format(new Date(data.contract_agreement_date), 'dd MMMM yyyy') : ''],
                            ['Nama Panjang Client', data.client_name],
                            ['Nomor Client', data.client_phone],
                            ['Email Client', data.client_email],
                            ['Cluster', data.cluster_name_label],
                            ['Unit  Information', data.unit_information],
                        ]}
                    />
                    <ModalSection
                        xs={12}
                        md={6}
                        title="Transaksi"
                        content={[
                            ['Payment Type', data.payment_type === 'kpr_express' ? 'KPR Express' : data.payment_type === 'kpr_reguler' ? 'KPR Reguler' : data.payment_type === 'cash_keras' ? 'Cash Keras' : data.payment_type === 'cash_bertahap' ? 'Cash Bertahap Developer' : '-'],
                            ['Payment Bank', data.payment_bank_name],
                            ['Nilai Transaksi', `Rp ${numberToCurrency(data.transaction_amount)}`],
                            permissions['closing.see-estimated-transaction'] ?
                                ['Estimasi Nilai Transaksi',
                                    permissions['closing.update-estimated-transaction'] ?
                                        <>
                                            {
                                                estimatedPriceForm.open ?
                                                    <div>
                                                        <TextField
                                                            // fullWidth
                                                            value={estimatedPriceForm.estimated_transaction_amount ? convertNumber(estimatedPriceForm.estimated_transaction_amount.toString()) : ''}
                                                            variant="outlined"
                                                            name="estimated_transaction_amount"
                                                            onChange={handleEstimatedPrice}
                                                        />
                                                        <IconButton aria-label="confirm" color="primary" onClick={handleSubmitEstimatedPrice}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="close" color="error" onClick={() => {
                                                            setEstimatedPriceForm(prev => ({
                                                                ...prev,
                                                                open: false,
                                                                estimated_transaction_amount: data.estimated_transaction_amount
                                                            }))
                                                        }}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                    :
                                                    <>
                                                        <span style={{ marginRight: 5 }}>{`Rp ${numberToCurrency(data.estimated_transaction_amount)}`}</span>
                                                        <IconButton aria-label="edit" color="primary" onClick={() => {
                                                            setEstimatedPriceForm(prev => ({
                                                                ...prev,
                                                                open: true,
                                                            }))
                                                        }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </>
                                            }
                                        </>
                                        : `Rp ${numberToCurrency(data.estimated_transaction_amount)}`
                                ]
                                : [],
                            data.payment_installments && data.payment_type === 'cash_bertahap' ?
                                ['Installment', `${data.payment_installments}x`]
                                : [],
                            data.down_payment_nominal && (data.payment_type === 'kpr_express' || data.payment_type === 'kpr_reguler') ?
                                ['Nilai Financing', data.down_payment_nominal ? `Rp ${numberToCurrency(data.down_payment_nominal)}` : '-']
                                :
                                [],
                            data.down_payment_installments && data.payment_type === 'kpr_reguler' ?
                                ['Cicilan DP (Bulan)', data.down_payment_installments ? `${data.down_payment_installments}x` : '-']
                                :
                                [],
                        ]}
                    />
                    <ModalSection
                        md={6}
                        xs={12}
                        title={"Notes"}
                        content={[
                            ['Notes', data.notes],
                        ]}
                    />
                    <ModalSection
                        md={6}
                        xs={12}
                        title={"Informasi Lainnya"}
                        row
                        content={[
                            [
                                [permissions['closing.change-agent'] ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant='body1' sx={{ fontWeight: 600 }}>Advisor 1</Typography>
                                        <IconButton
                                            size='small'
                                            onClick={() => changeAdvisor(0)}
                                        >
                                            <ChangeCircleIcon />
                                        </IconButton>
                                    </div> :
                                    'Advisor 1', `${data.agent_name} (${data.agent_full_name})`],
                                data.is_cobroke || data.is_telesales ? [
                                    permissions['closing.change-agent'] ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant='body1' sx={{ fontWeight: 600 }}>Advisor 1</Typography>
                                            <IconButton
                                                size='small'
                                                onClick={() => changeAdvisor(1)}
                                            >
                                                <ChangeCircleIcon />
                                            </IconButton>
                                        </div> :
                                        data.is_telesales ? 'Telesales' : 'Advisor 2'
                                    , data.cobroke_agent_name] : []
                            ],
                            [
                                ['Cabang Advisor 1', `${data.agent_group_name}`],
                                data.is_telesales ?
                                    [] :
                                    data.is_cobroke ? ['Advisor 2', data.cobroke_agent_group_name] : []
                            ],

                            // [
                            //     ['Pinjaman Komisi Advisor 1', data.borrowed_commission_amount ? `Rp ${numberToCurrency(data.borrowed_commission_amount)}` : '-'],
                            //     data.is_cobroke ? ['Pinjaman Komisi Advisor 2', data.cobroke_borrowed_commission_amount ? `Rp ${numberToCurrency(data.cobroke_borrowed_commission_amount)}` : '-'] : [],
                            // ],
                            // [
                            //     ['Tanggal Transfer Pinjaman Komisi Advisor 1', data.borrowed_commission_transfer_date ? format(new Date(data.borrowed_commission_transfer_date), 'dd MMMM yyyy') : '-'],
                            //     data.is_cobroke ? ['Tanggal Transfer Pinjaman Komisi Advisor 2', data.cobroke_borrowed_commission_transfer_date ? format(new Date(data.cobroke_borrowed_commission_transfer_date), 'dd MMMM yyyy') : '-'] : [],
                            // ],
                            [
                                ['Kode Pelunasan Pinjaman Advisor 1', data.loan_paid_closing_code ? data.loan_paid_closing_code : "-"],
                                data.is_cobroke ? ['Kode Pelunasan Pinjaman Advisor 2', data.loan_paid_cobroke_closing_code ? data.loan_paid_cobroke_closing_code : "-"] : [],
                            ],
                            [
                                data.is_cobroke ?
                                    ['Persentase Cobroke', data.cobroke_percentage ? `${Number(data.cobroke_percentage) * 100}%` : '-']
                                    :
                                    [],
                                []
                            ],
                        ]}
                    />
                    <Grid item xs={12}>
                        <div className={classes.actionButtonContainer}>
                            {/* {
                                (data.status === 'so_uploaded' && permissions['closing.complete']) &&
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => { openModalConfirmation('complete') }}
                                    >
                                        Complete Closing
                                    </Button>
                                </Grid>
                            } */}
                            {/* {
                                permissions['closing.manage-loan'] &&
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={handleListPinjamanModalOpen}
                                    >
                                        List Pinjaman
                                    </Button>
                                </Grid>
                            } */}
                            {
                                permissions['closing.cancel'] && !['cancel_payment', 'cancel_sales_order'].includes(data.status) &&
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={handleCancelModalOpen}
                                    >
                                        Cancel Closing
                                    </Button>
                                </Grid>
                            }

                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingScreen open={isFooterLoading} />
                        <Paper className={classes.paper}>
                            <TransactionList
                                closingId={closing_id}
                                data={data.transactions}
                                confirmRefreshData={() => fetchData()}
                                permissions={permissions}
                                setLoading={(loading) => {
                                    setIsLoading(loading)
                                }}
                            />
                        </Paper>
                    </Grid>

                    {permissions['closing-loan.manage'] &&
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <LoadingScreen open={isFooterLoading} />

                                <Tabs
                                    value={activePinjamanTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handlePinjamanTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="List Pinjaman" value={0} />
                                    <Tab label="List Pembayaran Pinjaman" value={1} />
                                </Tabs>
                                <div className={classes.footer}>
                                    {footerPinjaman}
                                </div>
                            </Paper>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <LoadingScreen open={isFooterLoading} />

                            <Tabs
                                value={activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTab}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="Files" value={0} />
                                <Tab label="Logs" value={1} />
                                {/* <Tab label="Transaksi" value={1} /> */}
                            </Tabs>
                            <div className={classes.footer}>
                                {footer}
                            </div>
                        </Paper>
                    </Grid>
                </>
            </ModalDetail>

            <Dialog
                open={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                scroll="body"
                maxWidth={false}
            >
                <img
                    src={data.booking_file}
                    alt="preview"
                    style={{
                        cursor: "pointer",
                        maxHeight: '80vh',
                        maxWidth: '80vw',
                        objectFit: 'scale-down',
                    }}
                />
            </Dialog>

        </Root>
    )
}

export default ClosingModalDetail
