import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton'
import DefaultAxios from '../../_utils/DefaultAxios';
import { CircularProgress } from '@mui/material';

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';

export interface IAutoCompleteOption {
    label: string;
    id: number | string;
    [key: string]: any;
}

interface Props {
    label: string;
    url: string;
    name: string;
    initialQuery?: string; //optional
    onChange: Function;
    onInputChange?: (e: any, name: string) => void;
    disabled?: boolean;
    errorText?: string;
    size?: 'small' | 'medium';
    iconSearch?: boolean;
    placeholder?: string;
    param?: string
    key?: number
    icon?: any;
    emptyOption?: IAutoCompleteOption
    withoutQuery?: boolean
    clearable?: boolean
    onClear?: () => void
}

let timeout: any;
const debounce = (func: () => void, wait: number = 1000) => {
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
    }
}

export default function AsyncAutoComplete(props: Props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<IAutoCompleteOption[]>([]);
    const [query, setQuery] = useState("");
    const loading = open && options.length === 0;
    const url_to_fetch = props.url;

    // Fetching Data
    useEffect(() => {
        if (!open) {
            setOptions([]);
        } else {
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, query, url_to_fetch]);

    useEffect(() => {
        if (typeof props.initialQuery !== 'undefined') {
            setQuery(props.initialQuery);
        }
    }, [props.initialQuery]);

    const loadData = debounce(() => {
        let param = (props.param && props.param !== '') ? props.param : 'search'
        DefaultAxios.get(url_to_fetch, { params: { [param]: query } })
            .then(res => {
                if (res.data.length) {
                    setOptions(res.data);
                } else {
                    setOptions(props.emptyOption ? [props.emptyOption] : [])
                }
            })
    })

    const clearQuery = () => {
        if (props.onClear) {
            props.onClear()
        }
        setQuery('')
    }

    return (
        <Autocomplete
            size={props.size}
            disabled={props.disabled}
            style={{ flexGrow: 1 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // TODO: might not work
            // Should be working now, but leave the the comment above to search for it easier later if problems occurs
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={option => option.display || option.label} // support different shown label and the final label
            options={options}
            loading={loading}
            inputValue={query}
            onChange={(e: any, value: any) => {
                props.onChange(props.name, value);
                if (props.withoutQuery) {
                    return setQuery("")
                }
                setQuery(value.label);
            }}
            filterOptions={props.emptyOption ? x => x : undefined} // disable text filtering
            value={null}
            popupIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
            renderInput={params => (
                <TextField
                    {...params}
                    label={props.label}
                    fullWidth
                    variant="outlined"
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        if (props.onInputChange) {
                            props.onInputChange(e, props.name);
                        }
                        setQuery(e.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <React.Fragment>
                                {props.iconSearch ? <SearchIcon style={{ color: "#9F9F9F" }} /> : (props.icon ? props.icon : null)}
                            </React.Fragment>
                        ),
                        ...(props.clearable && !loading && query !== '' && !props.disabled ? 
                        {
                            style: {paddingRight: 9},
                            endAdornment: (
                                <IconButton
                                    onClick={clearQuery}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )
                        } : {}
                        )
                    }}
                    error={!!props.errorText}
                    helperText={props.errorText}
                />
            )}
        />
    )
}