import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IconButton, Chip, Grid, FormControlLabel, Switch, Button, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import ClosingModalDetail from './_components/ClosingModalDetail';
import DeleteActivityModal from './_components/DeleteActivityModal';
import InputAgentMtsSelect from '../_lead/_components/InputAgentMtsSelect';
import EstimatedTransactionAmountForm from './_components/EstimatedTransactionAmountForm';
import LeadForm from '../_lead/LeadForm';

/**
 * Utils
*/
import helpers, { numberToCurrency } from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, inputNumber, renderWarningButton } from '../../_utils/Helper';
import { leadSources, leadMts } from '../../_utils/ConstData';
import { PermissionContext } from "../../_contexts/PermissionContext";
import { generateStyle } from '../../_utils/DefaultStyles';

/**
 * Icons
 */
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';


const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    priceContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}), "Closing_List")

type ClosingParams = { id: string | undefined }

const ClosingList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/closing-admin';
    const permissions = useContext(PermissionContext);
    const params = useParams<ClosingParams>();
    const navigate = useNavigate();
    const { Root, classes } = useStyles();
    const search = useLocation().search;
    const is_dm = new URLSearchParams(search).get('is_dm')

    const [closingId, setClosingId] = useState("");
    const [moreDetail, setMoreDetail] = useState(false);
    const [dmMode, setDmMode] = useState<boolean>(is_dm ? !!+(is_dm) : false)
    const [leadId, setLeadId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const [filter, setFilter] = useState({
        is_telesales: '',
    })

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setLeadId('');
        setModalOpen(false);
    }

    const handleEdit = (id: string) => {
        setLeadId(id);
        handleModalOpen();
    }

    const handleEditEstimatedPrice = useCallback((id: string, price: string, index: number) => {
        if (+price === 0) {
            return;
        }

        renderWarningButton("Apakah anda yakin ingin mengganti Estimasi nilai transaksi?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);

                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/closing-admin/update-estimated-transaction-amount`, { id: id, estimated_transaction_amount: inputNumber(price) })
                        .then(res => {
                            Swal.fire({
                                title: "Update berhasil",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    setReDrawDT(prev => prev + 1)
                                }
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }, [])

    useEffect(() => {
        if (params.id) {
            setClosingId(params.id);
        } else {
            setClosingId("");
        }
    }, [params.id]);

    const dm_columns = [
        {
            name: 'created_at',
            label: 'Tanggal Input',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
        {
            name: 'lead_date',
            label: 'Tanggal Leads',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true,
        },
        {
            name: 'group_name',
            label: 'Cabang',
            type: 'string',
            filterable: true,

        },
        {
            name: 'transaction_amount',
            label: 'Value',
            type: 'string',
            filterable: true,
            render: (data: number) => `Rp ${numberToCurrency(data)}`
        },
        {
            name: 'agent_name_full',
            label: 'Agent',
            type: 'string',
            filterable: true,
        },
        {
            name: 'cobroke_agent_name_full',
            label: 'Cobroke Agent',
            type: 'string',
            filterable: true,
        },
        {
            name: 'mts_label',
            label: 'MTS',
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                ...leadMts,
            ],
            filterable: true,
            sortable: false,
            render: (data: string, row: any) => {
                if (row.lead_source === 'input-agent' && permissions['lead.change-mts']) {
                    return <InputAgentMtsSelect
                        id={row.lead_id}
                        mts={row.lead_mts}
                        setIsLoading={setIsLoading}
                        submitCallback={() => {
                            setReDrawDT(new Date().getTime());
                        }}
                    />
                } else {
                    return data === 'undefined' ? '' : data;
                }
            }
        },
        {
            name: 'adsetname',
            label: 'Adset',
            type: 'string',
            filterable: true,
            render: (data: string) => (data ? data : '-')
        },
        {
            name: 'lead_cluster_name',
            label: '1St Cluster Ask',
            type: 'string',
            filterable: true,
        }
    ]

    const columns = [
        {
            name: 'created_at',
            label: 'Tanggal Input',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
        {
            name: 'utj_date',
            label: 'Tanggal UTJ',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
        {
            name: 'sales_order_date',
            label: 'Tanggal SP',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
        {
            name: 'contract_agreement_date',
            label: 'Tanggal Akad',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
        {
            name: 'status_label',
            label: 'Status',
            options: [
                { key: '', value: 'All' },
                { key: "draft", value: "Draft" },
                { key: 'so_uploaded', value: 'Sudah SP' },
                { key: 'not_complete', value: 'Lunas Sebagian' },
                { key: 'complete', value: 'Lunas Total' },
                { key: 'cancel_payment_office', value: 'Gagal UTJ Kantor' },
                { key: 'cancel_payment_developer', value: 'Gagal UTJ Developer' },
                { key: 'cancel_payment_kpr', value: 'Gagal Setelah SP' },
                { key: 'cancel_payment_installment', value: 'Gagal Cicilan' },
                { key: 'cancel_payment_other', value: 'Gagal Lainnya' },
            ],
            filterable: true,
        },
        {
            name: 'code',
            label: 'Kode',
            type: 'string',
            defaultOption: '',
            filterable: true,
            render: (data: string, row: any) => {
                if (data) {
                    return (
                        <Chip
                            variant="outlined"
                            label={row.code} onClick={() => {
                                setClosingId(row.id);
                                window.history.pushState('', '', `/closing/${row.id}`)
                            }}
                        />
                    )
                } else {
                    return "-";
                }
            }
        },
        {
            name: 'is_cobroke',
            label: 'Cobroke',
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: "1", value: "Yes" },
                { key: '0', value: 'No' },
            ],
            filterable: true,
            render: (data: any) => {
                return data === 0 ? 'No' : 'Yes';
            }
        },
        // {
        //     name: 'is_telesales',
        //     label: 'Closing Telesales',
        //     type: 'string',
        //     defaultOption: '',
        //     options: [
        //         { key: '', value: 'All' },
        //         { key: "1", value: "Yes" },
        //         { key: '0', value: 'No' },
        //     ],
        //     filterable: true,
        //     render: (data: any) => {
        //         return data === 0 ? 'No' : 'Yes';
        //     }
        // },
        {
            name: 'client_name',
            label: 'Nama Client',
            type: 'string',
            filterable: true,
        },
        {
            name: 'agent_name_full',
            label: 'Agent',
            type: 'string',
            filterable: true
        },
        {
            name: 'agent_group_name',
            label: 'Cabang',
            type: 'string',
            filterable: true,

        },
        {
            name: 'cobroke_agent_name_full',
            label: 'Cobroke Agent',
            type: 'string',
            filterable: true,
        },
        {
            name: 'cobroke_agent_group_name',
            label: 'Cabang (Cobroke)',
            type: 'string',
            filterable: true,

        },
        {
            name: 'developer_name',
            label: 'Developer',
            type: 'string',
            filterable: true,
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true,

        },
        {
            name: 'unit_information',
            label: 'Unit Detail',
            type: 'string',
            filterable: true
        },
        {
            name: 'transaction_amount',
            label: 'Nilai Transaksi',
            type: 'string',
            filterable: false,
            render: (data: number) => `Rp ${numberToCurrency(data)}`
        },
        ...(
            permissions['closing.see-estimated-transaction'] ?
                [{
                    name: 'estimated_transaction_amount',
                    label: 'Estimasi Nilai Transaksi',
                    type: 'number',
                    filterable: false,
                    style: {
                        minWidth: '250px'
                    },
                    render: (data: any, row: any, rows: any, index: number) => {
                        return (
                            permissions['closing.update-estimated-transaction'] ?
                                <EstimatedTransactionAmountForm
                                    initialValue={data}
                                    onSubmit={price => handleEditEstimatedPrice(row.id, price, index)}
                                />
                                : `Rp ${numberToCurrency(data)}`
                        )
                    }
                }] : []
        ),
        ...(
            moreDetail ? [
                {
                    name: 'mts_label',
                    label: 'Manual Tracking Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadMts,
                    ],
                    filterable: true,
                    sortable: false,
                    render: (data: string, row: any) => {
                        if (row.lead_source === 'input-agent' && permissions['lead.change-mts']) {
                            return <InputAgentMtsSelect
                                id={row.lead_id}
                                mts={row.lead_mts}
                                setIsLoading={setIsLoading}
                                submitCallback={() => {
                                    setReDrawDT(new Date().getTime());
                                }}
                            />
                        } else {
                            return data === 'undefined' ? '' : data;
                        }
                    }
                },
                {
                    name: 'source_label',
                    label: 'Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadSources
                    ],
                    filterable: true,
                    sortable: false,
                },
                {
                    name: 'notes',
                    label: 'Notes',
                    type: 'string',
                    filterable: false,
                    style: {
                        minWidth: '300px'
                    }
                },
                {
                    name: 'lead_date',
                    label: 'Tanggal Leads',
                    type: 'date',
                    filterable: true,
                    render: (data: any) => {
                        return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
                    }
                },
                {
                    name: 'lead_client_name',
                    label: 'Nama Client Lead',
                    type: 'string',
                    filterable: true,
                    render: (data: any, row: any) => {
                        return (
                            <span onClick={() => handleEdit(row.lead_id)} style={{ cursor: 'pointer', color: 'rgb(0, 86, 168)' }}>{data}</span>
                        )
                    }
                },
                {
                    name: 'lead_cluster_name',
                    label: '1st Cluster Ask',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'utm_source',
                    label: 'UTM Source',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_campaign',
                    label: 'UTM Campaign',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'referrer',
                    label: 'Referrer',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'keyword',
                    label: 'Keyword',
                    type: 'string',
                    filterable: true
                },
                // {
                //     name: 'gclid',
                //     label: 'Gclid',
                //     type: 'string',
                //     filterable: true
                // },
                // {
                //     name: 'fbclid',
                //     label: 'FBClid',
                //     type: 'string',
                //     filterable: true
                // },
                {
                    name: 'adsetname',
                    label: 'Adsetname',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adgroupid',
                    label: 'Adgroupid',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'creative',
                    label: 'Creative',
                    type: 'string',
                    filterable: true
                },
            ] : []
        ),
        ...(
            permissions['closing.delete']
                ? [
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <div className={classes.actionContainer}>
                                    <IconButton
                                        color="secondary"
                                        component="span"
                                        onClick={() => setDeletedId(row.id)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            );
                        }
                    }
                ]
                : []
        )
    ];

    const footerColumns = [
        {
            name: 'closing_date',
            render: () => {
                return 'Total';
            }
        },
        {
            name: 'transaction_amount',
            render: (data: number[]) => {
                return `Rp ${numberToCurrency(data.reduce((a, b) => {
                    return a + (b || 0);
                }, 0))}`;
            }
        },
        ...(
            permissions['closing.see-estimated-transaction']
                ? [
                    {
                        name: 'estimated_transaction_amount',
                        render: (data: number[]) => {
                            return `Rp ${numberToCurrency(data.reduce((a, b) => {
                                return a + (b || 0);
                            }, 0))}`;
                        }
                    }
                ]
                : []
        )
    ];

    const inputDeleteReason = useRef<HTMLInputElement>(null);
    const [deletedId, setDeletedId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDT, setReDrawDT] = useState(new Date().getTime());

    const handleDelete = () => {
        const reason = inputDeleteReason!.current!.value;

        if (!reason) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${API_URL}/${deletedId}/delete`, { reason })
            .then(res => {
                Swal.fire({
                    title: "Closing berhasil dihapus",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setReDrawDT(new Date().getTime());
                        setDeletedId('');
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const renderTelesalesFilter = (
        <BottomNavigation
            value={filter.is_telesales}
            onChange={(event, newValue) => {
                setFilter(prev => ({
                    ...prev,
                    is_telesales: newValue,
                }))
            }}
            showLabels
            className={classes.filter}
        >
            <BottomNavigationAction
                label="All"
                value={''}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
            <BottomNavigationAction
                label="Telesales"
                value={'1'}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
            <BottomNavigationAction
                label="Non Telesales"
                value={'0'}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
        </BottomNavigation>
    )

    const handleClickExport = () => {
        DefaultAxios.get(
            API_URL + `/export${window.location.search}`,
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `closing.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <LeadForm
                open={modalOpen}
                onClose={handleModalClose}
                leadId={leadId}
                viewOnly
            />

            <Grid container justifyContent="flex-end">
                <Grid item>
                    {
                        (permissions['closing.export'] || permissions['closing.export-telesales']) &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginBottom: '20px' }}
                            startIcon={<GetAppIcon />}
                            onClick={handleClickExport}
                        >
                            Export
                        </Button>
                    }
                </Grid>
            </Grid>

            <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item>
                    {permissions['closing.create'] &&
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            style={{ marginBottom: 10 }}
                            onClick={() => window.location.href = '/closing/add'}
                        >
                            Add Data
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {permissions['closing.more-detail'] &&
                        <Grid container justifyContent="flex-end">
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={dmMode}
                                            onChange={() => { setDmMode(!dmMode) }}
                                            color="primary"
                                        />
                                    }
                                    label="DM Mode"
                                />
                            </Grid>
                            {
                                !dmMode &&
                                <Grid>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={moreDetail}
                                                onChange={() => { setMoreDetail(!moreDetail) }}
                                                color="primary"
                                            />
                                        }
                                        label="More Detail"
                                    />
                                </Grid>
                            }
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Grid container>
                        <Grid>
                            {renderTelesalesFilter}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <DataTable
                url={API_URL}
                columns={dmMode ? dm_columns : columns}
                reDraw={reDrawDT}
                footerColumns={footerColumns}
                isHeaderSticky
                tableContainerMaxHeight={'calc(100vh - 230px)'}
                customParams={{ ...filter, is_dm: dmMode ? '1' : '0' }}
                customParamsInQueryParam
            />

            {closingId &&
                <ClosingModalDetail
                    permissions={permissions}
                    open={closingId !== ""}
                    closing_id={closingId}
                    onClose={() => {
                        navigate(`/closing`)
                        setClosingId("")
                        window.history.pushState('', '', `/closing`)
                    }}
                />

            }

            <DeleteActivityModal
                open={deletedId !== ''}
                onClose={() => setDeletedId('')}
                inputRef={inputDeleteReason}
                onDeleteActivity={handleDelete}
            />
        </Root>
    );
}

export default ClosingList;
